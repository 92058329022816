<template>
    <div >
            <HeaderHome :name="user.fullname" />
            <AnuncioBar />

             <div v-if="charge=='cargando'" id="purchaseDetail">
                    <div class="headerPD">
                        <h1>OBTENIENDO INFORMACIÓN DEL CARGO</h1>
                        <p>cargando...</p>
                        </div>
            </div>
               <div v-else-if="!charge" id="purchaseDetail">
                    <div class="headerPD">
                        <h1>HA OCURRIDO UN ERROR</h1>
                        <p>CARGO NO ENCONTRADO</p>
                        </div>
                        </div>
        
             <div v-else-if="charge.result.status=='charge_pending'" id="purchaseDetail">
                    <div class="headerPD">
                        <h1>CARGO PENDIENTE</h1>
                        <p>Tu cargo esta en proceso de validación.</p>
                        </div>
                        </div>
              <div v-else-if="charge.result.status=='completed'" id="purchaseDetail">
                    <div class="headerPD">
                        <h1>¡Gracias!</h1>
                        <p>Tu pedido ha sido recibido</p>
                       
                    </div>

                    <div v-if="getDetalles()" class="contentPD">
                           <div class="sec1_cpd">
                                <div class="sec1_cpd_titles">
                                    <p class="cpdt1">NÚMERO DEL PEDIDO</p>
                                    <p class="cpdt2">FECHA</p>
                                    <p class="cpdt3">TOTAL</p>
                                    <p class="cpdt4">MÉTODO DE PAGO</p>
                                </div>
                                <div class="sec1_cpd_data">
                                    <p class="cpdd1">{{getDetalles().pedido.numero_pedido}}</p> <!--  pendiente agregar numero pedido -->
                                    <p class="cpdd2">{{getDetalles().pedido.fecha_descr}}</p> <!--  pendiente agregar fecha -->
                                    <p class="cpdd3">${{getDetalles().cart.total}}</p>
                                    <p class="cpdd4" v-if="getDetalles().pedido.metodo_pago == 'OpenPay'">Openpay</p>
                                    <p class="cpdd4" v-else>{{getDetalles().pedido.metodo_pago}}</p>
                                </div>
                           </div>
                           <div class="tablaDP">
                               <div class="headerTPD">
                                   <p>DETALLES DEL <span clr>PEDIDO</span> </p>
                                   
                               </div>
                               <div class="contentTPD">
                                   <div class="sec1_cnt">
                                       <div class="sec1_cnt_prd">
                                           <div class="dflx">
                                               <p class="sctitle_r cntt1">PRODUCTO</p>
                                               <p class="sctitle_r">PRECIO</p>
                                           </div>
                                           <div class="items_cnt">
                                               <!-- productos comprados -->
                                               <div v-for="(item, key) in getDetalles().cart.detalle" :key="key" class="item_cnt dflx">
                                                  <div class="item_cntinf">
                                                    <p class="pbl">{{item.nombre}}</p> 
                                                    <p>{{item.talla}} | {{item.color}}</p>
                                                    <p>{{item.descripcion}}</p>
                                                    <p class="pbl">{{item.cantidad}}</p>
                                                  </div>
                                                  <div class="item_cntprc">
                                                      <p>${{item.precio}}</p>
                                                  </div>
                                                   
                                               </div >
                                               
                                               

                                           </div>
                                       </div>
                                       <div class="border_cnt">

                                       </div>
                                       <div class="sec2_cnt_sub">
                                             <div class="dflx  cntsub">
                                                   <p class="sctitle_r sect2_cntt1">SUBTOTAL</p>    
                                                    <p class="DPSubtotal">${{getDetalles().cart.subtotal}}</p>
                                               
                                           </div>
                                           <div class="dflx">
                                                <p class="sctitle_r sect2_cntt1">CUPÓN DE DESCUENTO</p>
                                                <p class="DPEnvio">-${{getDetalles().cart.descupon}}</p> 
                                            </div>
                                           <div class="dflx">

                                                <p class="sctitle_r sect2_cntt1"> ENVÍO</p>
                                                <p class="DPEnvio"> ${{getDetalles().cart.envio}}</p>
                                           </div>
                                       </div>
                                       <div class="border_cnt">

                                       </div>
                                       <div class="sec3_cnt_mtd">
                                            <div class="dflx cntmtd">
                                                   <p class="sctitle_r sect3_cntt1">MÉTODO <br> DE PAGO</p>
                                                   <p  v-if="getDetalles().pedido.metodo_pago =='OpenPay'" class="sect3_cntt2">Openpay</p>
                                                    <p v-else class="sect3_cntt2">{{getDetalles().pedido.metodo_pago}}</p>
                                               
                                           </div>
                                           <div class="dflx">

                                                <p class="sctitle_r sect3_cntt1">TOTAL</p>
                                                <p class="sect3_cntt3"> ${{getDetalles().cart.total}}</p>
                                           </div>
                                       </div>
                                   </div>
                                   <div class="border_cnt_b"> </div>
                                   <div class="sec2_cnt_fac" v-if="getDetalles().facturacion.facturar"> <!-- si existe facturacion  -->
                                        <div class="dflx">
                                            <p class="sctitle_r .pbl fact1">RAZÓN SOCIAL</p>
                                            <p>{{getDetalles().facturacion.razon_social}}</p>
                                        </div>
                                        <div class="dflx">
                                            <p class="sctitle_r .pbl fact1">RFC</p>
                                            <p>{{getDetalles().facturacion.rfc}}</p>
                                        </div>
                                        <div class="dflx">
                                            <p class="sctitle_r .pbl fact1">DIRECCIÓN FISCAL</p>
                                            <p>{{getDetalles().facturacion.direccion_fiscal}}</p>
                                        </div>
                                        <div class="dflx">
                                            <p class="sctitle_r  .pbl fact1">CÓDIGO POSTAL</p>
                                            <p>{{getDetalles().facturacion.codigo_postal}}</p>
                                        </div>
                                        <div class="dflx">
                                            <p class="sctitle_r .pbl fact2">CORREO ELECTRÓNICO</p>
                                            <p>{{getDetalles().facturacion.email_fac}}</p>
                                        </div>

                                       
                                   </div>
                                   <div class="border_cnt_b"> </div>
                                   <div v-if="valCupones(getDetalles().cart.cupon)">
                                     
                                      <p class="subtitle">CUPONES</p>
                                      <div class="border_cnt_b"> </div>
                                       <table class="Cupones">
                                        <tr>
                                            <th class="Code_TC">CÓDIGO</th>
                                            <th class="Desc_TC">DESCUENTO</th>
                                            <th class="Desc_TC">ENVÍO GRATIS</th>
                                        </tr>
                                        <tr class="F2_TC" v-for="(item, key) in getDetalles().cart.cupon" :key="key">
                                            <td class="DCode_TC">{{item.code}}</td>
                                            <td class="DDesc_TC">-${{item.descuento}}</td>
                                            <td class="DDesc_TC">{{item.freeShipping}}</td>
                                        </tr>
                                        </table>
                                   </div>
                                 
                               </div>
                           </div>
                    </div>

              </div>
              <div v-else id="purchaseDetail">
                 <div class="headerPD">
                        <h1>TRANSACCIÓN FALLIDA</h1>
                        <p>El cargo no fue autorizado.</p>
                        </div>
              </div>
              <Footer />
    </div>
</template>
<script>



import Footer from "../../components/footer";
import HeaderHome from "../../components/HeaderHome";
import AnuncioBar from "../../components/AnuncioBar";
import dayjs from "dayjs";
import 'dayjs/locale/es' // load on demand

export default {
    name:'purchaseDetail',
    components: {
        HeaderHome,
        Footer,
        AnuncioBar
     },
    data(){
        return {
            status:'',
            msg:'',
            charge_pending:true,
            charge:'cargando'
        }
    },
    async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
    async created(){
        const {id} = this.$route.query
        if(!id){
             this.$router.push("/").catch(err => {});
        }

        //enviar a validacion.
       this.charge = await  this.$store.dispatch("main/editItem",  {option:'pedidos/charge_status', item:{ "order_id":id}});
       //console.log(this.charge)


      
       
         
          
    },
    computed:{
        user() {
            return this.$store.getters["admin/getIdentity"];
            },
    },
    methods:{
valCupones(arr){

    if(!Array.isArray(arr)) return false
    if(arr.length ==0) return false;

    return  true
   

},

        getDetalles: function(){
            this.status =''
            this.msg =''

           let detalles =  localStorage.getItem('COMPLETED')
            if(detalles == undefined || detalles == null){
               this.status =='error'
               this.msg =='No se encontraron los detalles de la compra'
               
            }else{
                this.status =='success'
               this.msg =='Detalles de la compra'
               detalles = JSON.parse(detalles)
            }
           return detalles
        },
        
    }
}
</script>

<style scoped>
    #purchaseDetail{
        font-family: "Rajdhani";
    }
    p.subtitle{
         color: var(--color-8);
        font-weight: 700;
        font-size: 1.75VW;
        padding-top: 0.78125VW;
        margin-left: 6vw;
    }
    .headerPD{
        height: 10.260416666666666VW;
        margin-top: 4.583333333333333VW;
    }.headerPD h1{
        text-align: center;
        color: var(--color-8);
        font-weight: 700;
        font-size: 3.75VW;
        padding-top: 0.78125VW;
    }.headerPD p{
        text-align: center;
        color: var(--color-3);
        font-size: 1.875VW;
        font-family: 'Rajdhani';
    }.sec1_cpd_titles{
        margin-top: 3.4895833333333335VW;
        display: flex;
        margin-left: 16.614583333333332VW;
        color: var(--color-2);
    }.sec1_cpd_titles p{
        font-size: 1.25VW;
        font-weight: 700;
        text-align: center;
        text-decoration: underline;
    }.cpdt1{
        width: 14.531250000000002VW;
        margin-right: 7.291666666666667VW;
    }.cpdt2{
        width: 2.96875VW;
        margin-right: 11.041666666666666VW;
    }.cpdt3{
        width: 2.8125VW;
        margin-right: 11.458333333333332VW;
    }.cpdt4{
        width: 12.239583333333332VW;
    }.sec1_cpd_data{
        display: flex;
        margin-left: 18.614583VW;
        color: var(--color-3);
    }.sec1_cpd_data p{
        font-size: 1.25VW;
        text-align: center;
        font-family: "Rajdhani";
        font-weight: 600;
    }.cpdd1{
        width: 10.416666666666668VW;
        margin-right: 5.791667VW;
    }.cpdd2{
        width: 10.416666666666668VW;
         margin-right: 3.641667VW;
    }.cpdd3{
        width: 10.416666666666668VW;
        margin-right: 3.458333VW;
    }.cpdd4{
        width: 20.46875VW;
    }

    .tablaDP{
         margin-top: 3.4375000000000004VW;
        width: 67.5VW;
        margin-left: 16.25VW;
        border: 1px solid var(--color-3);
        border-radius: 2.7vw;
        margin-bottom: 10vw;
    }.headerTPD{
        height: 5.677083333333333VW;
        background-color: #B70D1D;
        border-radius: 2.604166666666667VW 2.604166666666667VW 0vw 0vw;
    }.headerTPD p{
        width: 19.88645833333333VW;
        font-size: 1.875VW;
        font-family: "Rajdhani";
        color: var(--color-4);
        margin-left: 23.802083333333332VW;
        padding-top: 1.7708333333333333VW;
    }.headerTPD p span{
         color: var(--color-4);
         font-family: "Rajdhani";
    }.contentTPD{
        min-height: 35.208333333333336VW;
        background-color: white;
        margin-bottom: 5.052083333333333VW;
        padding-top: 2.9166666666666665VW;
        font-weight: 600;
        border-radius: 0vw 0vw 2.604166666666667VW 2.604166666666667VW;
    }

    .sec1_cnt{
        margin-bottom: 2.2916666666666665VW;
         font-size: 1.2583333333333333VW;
         font-family: "Rajdhani";
    }.hr_h{
        border: 1px solid #B70D1D;
       
    }.sec1_cnt{
        display: flex;
    }

    .sctitle_r{
        font-family: "Rajdhani";
        font-weight: 700;
        color: var(--color-1);
    }
    
    .dflx{
        display: flex;
    }

    .border_cnt{
        border-right: 0.11VW solid #B70D1D;
          margin-top: 1.2vw;
    }

     .border_cnt_b{
        border-bottom: 0.11VW solid #B70D1D;
        width: 55.51666666666667VW;
        margin-left: 5.989583333333334VW;
        margin-bottom: 2.8125VW;
    }

    .sec1_cnt_prd{
        width: 21.1453125VW;
        margin-left: 5.3125VW;
       
        
    }.cntt1{
          margin-right: 6.95VW;
    }

    .sec2_cnt_sub{
           width: 15.854167VW;
    }
    .item_cnt{
        padding-top: 0.8854166666666666VW;
    }.items_cnt p{
        width: 10.208333333333334VW;
        color: var(--color-1);
        margin-bottom: 0vw;
       
    }.pbl{
       font-family: "Rajdhani";
    }.item_cntprc{
        text-align: center;
    }


    .cntsub{
        margin-bottom: 4.53125VW;
    }
    .sec2_cnt_sub{
        margin-left: 2.604166666666667VW;
    }

    .sect2_cntt1{
         width: 7VW;
        white-space: pre-wrap;
    }

    .cntmtd{
        margin-bottom: 2.96875VW;
    }

    .sec3_cnt_mtd{
        margin-left: 2.2916666666666665VW;
    }
    .sect3_cntt1{
           width: 6vw;
    }.sect3_cntt2{
          width: 8.708333VW;
    }.sect3_cntt3{
        width: 8.708333VW;
        color: var(--color-2);
    }

    .sec2_cnt_fac{
        margin-left: 5.3125VW;
          font-family: "Rajdhani";
          font-family: 1.25VW;
    }
    
    .fact1,.fact2{
        width: 11.393229166666668VW;
        margin-right: 3.4505208333333335VW;
    }
    .fact1 {
        margin-bottom: 1.3541666666666667VW;
        
    }
    .fact2{
       
       margin-bottom: 3.229166666666667VW;
    }

    .Cupones{
        width: 60%;
        height: fit-content;
        margin-left: 6vw;
    }
    th.Code_TC {
        color: #032A4B;
        font-size: 1.25VW;
    }
    th.Desc_TC {
        color: #032A4B;
        font-size: 1.25VW;
    }
    td.DCode_TC {
    color: var(--color-1);
    font-size: 1.25VW;
    font-family: "Rajdhani";
    }
    td.DDesc_TC {
    color: var(--color-1);
    font-size: 1.25VW;
    font-family: "Rajdhani";
    }
    
</style>